import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
//import { Link, NavLink } from 'react-router-dom';
import PostList from './components/PostList';
import  Footer from './components/Footer';
import  ModalCovid from './components/Modal';
import Helmet from 'react-helmet';
import { Social } from './components/Social';
import ProjectList from './components/ProjectList';
import NavigationBar from './components/NavigationBar';

// import i18n
import './i18n';

// contenuti
import imgSlide1 from './assets/img/home/slide1_1.jpg';
import imgSlide2 from './assets/img/home/slide2_2.jpg';
import imgSlide3 from './assets/img/home/slide3_3.jpg';
import imgSlide4 from './assets/img/home/slide4_4.jpg';
import imgContatti from './assets/img/home/slide_contatti.jpg';
import imgStudio1 from './assets/img/location/location1.jpg';
import imgStudio2 from './assets/img/location/location2.jpg';
import imgStudio3 from './assets/img/location/location3.jpg';
import imgStudio4 from './assets/img/location/location4.jpg';
import imgStudio5 from './assets/img/location/location5.jpg';
import imgStudio6 from './assets/img/location/location6.jpg';
import imgStudio7 from './assets/img/location/location7.jpg';
import imgStudio8 from './assets/img/location/location8.jpg';
import imgStudio9 from './assets/img/location/location9.jpg';
import imgStudio10 from './assets/img/location/location10.jpg';
import imgStudio11 from './assets/img/location/location11.jpg';
import imgStudio12 from './assets/img/location/location12.jpg';



const Home = () => {

    return(
        <div className="page">
            <Helmet>
                <title>Annagrazia D'Antico | a step together</title>
                <meta name="description" content="Annagrazia D'Antico Dance Studio: lezioni di danza moderna, danza contemporanea, metodo Graham e percorsi di allenamento Gravity Training System." />
            </Helmet>
            <NavigationBar isSmall={false} />
            <Container className="px-0" fluid="true">
                <section className="home-section">
                    <h1 className="sr-only">Annagrazia D'Antico</h1>
                    <Row className="align-items-center">
                        <Col xs={12} className="mx-auto p-0">
                            <Carousel fade="true" controls={false} interval="3000" pause="false" className="mx-auto" >
                                <Carousel.Item className="carousel-item-home" style={{ 'backgroundImage': 'url('+imgSlide1+')'}}>
                                    <span className="sr-only">Slide che illustra il percorso "Dance Studio"</span>
                                </Carousel.Item>
                                <Carousel.Item className="carousel-item-home" style={{ 'backgroundImage': 'url('+imgSlide2+')'}}>
                                    <span className="sr-only">Slide che illustra il percorso "Gravity"</span>
                                </Carousel.Item>
                                <Carousel.Item  className="carousel-item-home" style={{ 'backgroundImage': 'url('+imgSlide3+')'}}>
                                    <span className="sr-only">Slide che illustra il percorso "Postural path"</span>
                                </Carousel.Item>
                                <Carousel.Item  className="carousel-item-home" style={{ 'backgroundImage': 'url('+imgSlide4+')'}}>
                                    <span className="sr-only">Slide che illustra Annagrazia</span>
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </section>
                <section className="home-section bg-grigio home-section-percorsi pt-5" >
                    <Row className="pt-5">
                        <Col md={12} className="text-center pt-5">
                            <h2 className="title title-home">Percorsi</h2>
                        </Col>
                        <Col md={12} className="py-5 mb-5">
                            <ProjectList inHome={true} />
                        </Col>
                    </Row>
                </section>
                <section className="home-section px-md-0 position-relative" style={{ 'overflowY': 'visible'}}>
                    <Row className="align-items-center">
                        <Col md={5} className="mr-auto pl-md-5 py-5">
                            <div className="location-box px-3">
                                <h2 className="title title-home">Studio</h2>
                                <h3 className="subtitle-home mb-4">Un ambiente a misura d’uomo</h3>
                                <p>Un ambiente familiare ed accogliente nel silenzioso interno cortile di un palazzo ottocentesco</p>
                                {/* <Button><NavLink to="/bio">Leggi tutto</NavLink></Button> */}
                            </div>
                        </Col>
                        <Col md={7} className="ml-auto pl-md-5 py-5 home-section-location">
                            <Carousel className="mb-4" fade="true" controls={false} interval="3000" pause="false">
                                <Carousel.Item className="slider-studio" style={{ 'backgroundImage': 'url('+imgStudio1+')'}}></Carousel.Item>
                                <Carousel.Item className="slider-studio" style={{ 'backgroundImage': 'url('+imgStudio2+')'}}></Carousel.Item>
                                <Carousel.Item className="slider-studio" style={{ 'backgroundImage': 'url('+imgStudio3+')'}}></Carousel.Item>
                                <Carousel.Item className="slider-studio" style={{ 'backgroundImage': 'url('+imgStudio4+')'}}></Carousel.Item>
                                <Carousel.Item className="slider-studio" style={{ 'backgroundImage': 'url('+imgStudio5+')'}}></Carousel.Item>
                                <Carousel.Item className="slider-studio" style={{ 'backgroundImage': 'url('+imgStudio6+')'}}></Carousel.Item>
                                <Carousel.Item className="slider-studio" style={{ 'backgroundImage': 'url('+imgStudio7+')'}}></Carousel.Item>
                                <Carousel.Item className="slider-studio" style={{ 'backgroundImage': 'url('+imgStudio8+')'}}></Carousel.Item>
                                <Carousel.Item className="slider-studio" style={{ 'backgroundImage': 'url('+imgStudio9+')'}}></Carousel.Item>
                                <Carousel.Item className="slider-studio" style={{ 'backgroundImage': 'url('+imgStudio10+')'}}></Carousel.Item>
                                <Carousel.Item className="slider-studio" style={{ 'backgroundImage': 'url('+imgStudio11+')'}}></Carousel.Item>
                                <Carousel.Item className="slider-studio" style={{ 'backgroundImage': 'url('+imgStudio12+')'}}></Carousel.Item>
                            </Carousel>
                        </Col> 
                    </Row>
                </section>
                <section className="home-section py-5 bg-grigio-chiaro">
                    <h2 className="title title-home text-center mt-5">News</h2>
                    <h3 className="subtitle-home text-center pb-3">Le mie news e i miei eventi</h3>
                    <Row className="mt-5">
                        <Col md={10} xl={8} className="mx-md-auto mx-3">
                            <PostList nrOfPost="3" inHome={true} />
                        </Col>
                    </Row>
                </section>
                <section className="" >
                    <Row className="align-items-center">
                        <Col md={6}>
                        <img src={imgContatti} className="img-fluid" alt="Annagrazia D'Antico" />
                        </Col>
                        <Col md={6} className="text-center">
                            <h2 className="title title-home mt-3">Contatti</h2>
                            <h3 className="subtitle-home">Dove trovarmi</h3>
                            <div className="">
                                <p className="mt-4 mb-0"><a className="general-link general-link-big" href="mailto:info@ada-dancestudio.com">info@ada-dancestudio.com</a><br></br><a className="general-link general-link-big" href="tel:01119015408">011 190 154 08</a><br/><a className="general-link general-link-big" href="tel:3454425642">+39 345 442 5642</a></p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <Social inHome={true}/>
                            </div>
                        </Col>
                    </Row>
                </section>
            </Container>

            
            <Footer />
            
        </div>

    )
}

export default Home