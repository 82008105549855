import React from 'react';

function Footer (){

    return ( 
   <footer className="text-center p-3">
       <p className="mb-0">Copyright © 2020 All rights reserved | <a href="/Privacy">Cookie Policy</a> | <a href="https://doppiozero.to/">Credits</a></p>
   </footer>
    )

}


export default Footer
