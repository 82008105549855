import React, { Component } from 'react';
import axios from 'axios';
import { Col } from 'react-bootstrap';
import 'moment/locale/it';


class StaffList extends Component {

    state = {
        posts: [ ]
    }

    componentDidMount(){
        var nrOfPost = this.props.nrOfPost;
        axios.get(process.env.REACT_APP_BACKEND_URL + '/collaboratoris?_sort=nome:DESC')
            .then(res => {
                console.log(res)
                this.setState({
                    posts: res.data.slice(0,nrOfPost)
                })
            })
    }


    render() {
        
        const { posts } = this.state;
        const staffList = posts.length ? (
            posts.reverse().map( (post, index) => {
                
                return (
                    <Col md={10} lg={6} key={index} className="post-link collaboratori mb-5 d-block pb-1 mx-md-auto">
                        <div className="post card mb-5 px-lg-3">
                            <div className={ this.props.inHome ? ("card-body d-flex p-0 row"):("card-body d-flex p-0 bg-grigio-chiaro row")} >
                                <img
                                    className="d-block p-lg-0 col-sm-5"
                                    src={process.env.REACT_APP_BACKEND_URL + post.foto.url}
                                    alt={post.nome}
                                />
                                <div className="card-content pl-4 pr-4 col-sm-7">
                                    <h3 className="mb-3 mt-2">{post.nome}</h3>
                                    <p>{post.ruolo}</p>
                                    {post.curriculum ? 
                                        (
                                            <a href={process.env.REACT_APP_BACKEND_URL + post.curriculum.url} className="curriculum d-inline-block mb-4" target="_blanck">Scarica CV</a>
                                        ) : 
                                        (
                                            <span></span>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                )
            })
        ) : (
            <div className="text-center">Non ci sono collaboratori al momento</div>
        )

        return(
            <div className="row px-3">
                {staffList}
            </div>
        )
    }
}

export default StaffList