import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import StaffList from './components/StaffList'
import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';


class Collaboratori extends Component {

    render() {
        return(
            <div className="page pt-120">
                <Helmet>
                    <title>Collaboratori del AD'A Dance Studio | Annagrazia D'Antico</title>
                    <meta name="description" content="AD'A Dance Studio si avvale di uno staff fatto di collaboratori di grande professionalità, un gruppo di insegnanti competente per le varie lezioni. " />
                </Helmet>
                <NavigationBar isSmall={true} />
                <Container fluid="true">
                    <Row className="align-items-center">
                        <Col xl={12} className="mb-5">
                            <h1 className="title text-center">Collaboratori</h1>
                            <h2 className="subtitle text-center">Lo staff</h2>
                        </Col>
                        <Col xl={11} className="mx-auto">
                            <StaffList className="row" />
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}

export default Collaboratori