import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
import styled from 'styled-components';

// contenuti
import imgLogo from '../assets/img/logo_ada.png';
import imgBrush from '../assets/img/brown-brushstroke.png';



// export const NavigationBar = (props) => (
//     <Styles>
//         <Navbar expand="lg" className=" fixed-top"><Container fluid="true">
//             <Navbar.Brand><NavLink to="/" className="logo text-uppercase"><img src={imgLogo} alt="Annagrazia D'Antico" className="img-fluid"  /></NavLink></Navbar.Brand>
//             <Navbar.Toggle aria-controls="basic-navbar-nav" />
//             <Navbar.Collapse id="basic-navbar-nav">
//                 <Nav className="ml-auto">
//                     <Nav.Item><NavLink to="/bio">Bio</NavLink></Nav.Item>
//                     <Nav.Item><NavLink to="/percorsi">Percorsi</NavLink></Nav.Item>
//                     <Nav.Item><NavLink to="/blog">Blog</NavLink></Nav.Item>
//                     <Nav.Item><NavLink to="/contatti">Contatti</NavLink></Nav.Item>
//                 </Nav>
//             </Navbar.Collapse></Container>
//         </Navbar>
//     </Styles>
// )



class NavigationBar extends React.Component {
   
    componentDidMount() {

     
     console.log('props isSmall ', this.props.isSmall)

     if(this.props.isSmall === false){
        window.addEventListener("scroll", this.resizeHeaderOnScroll); 
        console.log('riattivo')
     }
     
    }


    resizeHeaderOnScroll() {
      const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      //const distanceY = window.scrollY,
        shrinkOn = 100,
        headerEl = document.getElementById("js-header"); 
        
      if (distanceY > shrinkOn) {
        headerEl.classList.add("smaller");
      } else {
        headerEl.classList.remove("smaller");
      }

     
  
      
    }

    componentWillUnmount() {

      if(this.props.isSmall === false){
        window.removeEventListener('scroll', this.resizeHeaderOnScroll);
        console.log('rimuovo')
     }
    
  }

    setSmall(){
    
    }
    render() {
        const Styles = styled.div`
        .navbar {
            //background-color: rgba(255,255,255,.8);
        }
        // .navbar-brand, .navbar-nav .nav-link{
        //     color: #bbb;
        //     &:hover{
        //         color: white;
        //     }
        // }
        .nav-item a{
            padding: 5px 10px;
            color: #000;
            position: relative;
            &:before{
                content: "";
                display: block;
                position: absolute;
                bottom: -2px;
                left: 15px;
                z-index: -1;
                height: 8px;
                width: 0;
                background: url(${imgBrush});
                background-repeat: no-repeat;
background-size: 100% 95%;
padding: 4px 0;
                transition: width .35s ease;
            }
            &:hover{
                text-decoration: none;
                &:before{
                    width: calc(100% - 20px);
                    transition: width .35s ease;
                }
            }
        }
        .nav-item a.active{
            &:before{
                width: calc(100% - 20px);
            }
        }
    `;
    
      return (
        <Styles>
        <Navbar expand="lg" id="js-header" className={this.props.isSmall ? ("js-header fixed-top smaller"):("js-header fixed-top")}>
          <Container fluid>
            <Navbar.Brand className="d-inline-block">
              <NavLink to="/" className="logo text-uppercase"><img src={imgLogo} alt="Annagrazia D'Antico" className="img-fluid"  /></NavLink>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="pb-2 pr-3 pr-lg-0 text-right">
                <Nav className="ml-auto">
                    <Nav.Item><NavLink to="/bio" id="header-bio">Bio</NavLink></Nav.Item>
                    <Nav.Item><NavLink to="/percorsi">Percorsi</NavLink></Nav.Item>
                    <Nav.Item><NavLink to="/orari">Orari</NavLink></Nav.Item>
                    <Nav.Item><NavLink to="/blog">News</NavLink></Nav.Item>
                    <Nav.Item><NavLink to="/collaboratori">Collaboratori</NavLink></Nav.Item>
                    <Nav.Item><NavLink to="/contatti">Contatti</NavLink></Nav.Item>
                </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
    </Styles>
      );
    }
  }
  

  export default NavigationBar;