import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { Parallax } from 'react-scroll-parallax';


class ProjectList extends Component {

    state = {
        projects: [ ],
    }

    componentDidMount(){

        axios.get(process.env.REACT_APP_BACKEND_URL + '/percorsos?_sort=id:ASC')
            .then(res => {
                console.log(res)
                this.setState({
                    projects: res.data.slice(0,3),
                })
                
            })
    }


    render() {

        const { projects } = this.state;
        const projectList = projects.length ? (
            projects.map( (project, index) => {
                return (
                    
                    <Row key={project.id} className="percorso-wrap mx-auto pb-5 pt-5">
                        <Col md={5} sm={12} className={(index%2 ===0)?('ml-auto mr-md-5'):('mr-auto ml-md-5')}>
                            <Parallax className="custom-class"  x={(index===0)?([-70, 70]):(index===1)?([100, -150]):([-300, 270])} >
                                <div  className="parallax-img" style={{ 'backgroundImage': 'url('+process.env.REACT_APP_BACKEND_URL + project.sfondo.url+')', 'height': '300px'}}>
                                </div>
                                {/* <img src={process.env.REACT_APP_BACKEND_URL + project.sfondo.url} alt="" class="parallax-img"/>  */}
                            </Parallax>
                            <Link to={'/percorsi/' + project.id } className="percorso-link" >
                               <Card className={this.props.inHome ? ("percorso percorso-card"):("percorso percorso-card percorso-card-grigia")}> 
                               <div className="percorso-imagewrap">
                               <Card.Img variant="top" src={process.env.REACT_APP_BACKEND_URL + project.anteprima.url} /></div>
                                   <Card.Body className="p-4">
                                       <Card.Title>{project.titolo}</Card.Title>
                                       <h4 className="card-subtitle mb-3 mt-1">{project.sottotitolo}</h4>
                                       <Card.Text>
                                           {project.intro}
                                       </Card.Text>
                                       <Button variant="primary">Scopri</Button>
                                   </Card.Body> 
                               </Card>
                           </Link>
                        </Col>
                    </Row>
                    // this.props.inHome ? (
                    // ) : (
                    //     <Col sm={5} key={project.id} className="percorso-wrap mx-auto mb-5 mt-5">
                    //         <Link to={'/percorsi/' + project.id } className="percorso-link" >
                    //         <Card className="percorso cornice-1">
                    //             <Card.Img variant="top" src={process.env.REACT_APP_BACKEND_URL + project.anteprima[0].url} />
                    //             <Card.Body>
                    //                 <Card.Title>{project.titolo}</Card.Title>
                    //                 <h4 className="card-subtitle mb-3 mt-1">{project.sottotitolo}</h4>
                    //                 <Card.Text>
                    //                     {project.intro}
                    //                 </Card.Text>
                    //                 <Button variant="primary">Scopri</Button>
                    //             </Card.Body>
                    //         </Card>
                    //     </Link>
                    // </Col>
                    // )
                        
                )
            })
        ) : (
            <div className="text-center">Loading...</div>
        )

        return(
            <div>
                {projectList}
                <Col sm={5} className="percorso-wrap mx-auto"></Col>
            </div>
        )
    }
}

export default ProjectList