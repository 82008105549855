import React, {useState} from 'react';

import Modal from 'react-bootstrap/Modal'


// import i18n
import '../i18n';
import ada_chiusura from '../assets/img/ada_comunicazione3.jpg';


function ModalCovid() {
    const [show, setShow] = useState(true);
  
    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
  
    return (
      <>
  
        <Modal
          show={show}
          onHide={handleClose}
          centered
          className="modaleAvviso"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img className="img-fluid" src={ada_chiusura} alt="Lo staff è lieto di accogliervi e di ricominciare insieme e vi invita all'OPEN DAY che si svolgerà domenica 12/09 ORE 15,00. Come sempre rispetteremo tutte le regole necessarie al contenimento, imposte dalla normativa anti Covid. " width="478" />
            <div className="px-3 py-4 text-center">
              <h2>A STEP TOGETHER</h2>
              <p>Annagrazia e lo Staff di AD’A Dance Studio</p>
              <a href="mailto:info@ada-dancestudio.com" target="_blank" rel="noopener noreferrer" className="btn btn-primary my-2">Prenota il tuo posto all'open day del 12/09/2021</a>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
  
  export default ModalCovid