import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment/locale/it';
import ReactMarkdown from 'react-markdown';
import ImgThumbnail from '../assets/img/thumbnail.jpg';

class PostList extends Component {

    state = {
        posts: [ ]
    }

    componentDidMount(){
        var nrOfPost = this.props.nrOfPost;
        axios.get(process.env.REACT_APP_BACKEND_URL + '/articolos?_sort=data:DESC')
            .then(res => {
                console.log(res)
                this.setState({
                    posts: res.data.slice(0,nrOfPost)
                })
            })

        // var nrOfPost3 = this.props.nrOfPost3;
        // axios.get(process.env.REACT_APP_BACKEND_URL + '/articolos?_limit=3&_sort=id:DESC')
        //     .then(res => {
        //         console.log(res)
        //         this.setState({
        //             posts: res.data.slice(0,nrOfPost3)
        //         })
        //     })
    }


    render() {
        
        const { posts } = this.state;
        const postList = posts.length ? (
            posts.map( (post, index) => {
                
                return (
                    <Link to={'/blog/' + post.id } key={index} className="post-link mb-5 d-block pb-1">
                        <div className="post card mb-5">
                            <div className={ this.props.inHome ? ("card-body d-flex p-0 row"):("card-body d-flex p-0 bg-grigio-chiaro row")} >
                                <img
                                    className="d-block col-md-5"
                                    src={post.cover ? process.env.REACT_APP_BACKEND_URL + post.cover.url : ImgThumbnail}
                                    alt={post.titolo}
                                />
                                <div className="card-content pl-md-4 pr-md-4 col-md-7">
                                    <span className="post-date mt-3 d-block">
                                        <Moment locale="it" format="DD MMMM YYYY">{post.data}</Moment>
                                    </span>
                                    <h3 className="mb-3 mt-2">{post.titolo}</h3>
                                    <ReactMarkdown source={`${post.testo.slice(0,200)}...`} />
                                </div>
                            </div>
                        </div>
                    </Link>
                )
            })
        ) : (
            <div className="text-center">Non ci sono post</div>
        )

        return(
            <div class="px-3">
                {postList}
            </div>
        )
    }
}

export default PostList