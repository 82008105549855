import React, { Component } from 'react';
import axios from 'axios';
import Helmet from 'react-helmet';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import ReactMarkdown from 'react-markdown';


class Project extends Component{

    state = {
        project: null
    }

    componentDidMount(){
        
        var id = this.props.match.params.project_id;

        console.log("id", id)

        axios.get(process.env.REACT_APP_BACKEND_URL + '/percorsos/' + id)
            .then(res => {
                this.setState({
                    project: res.data
                })
            })
       
        // axios.get('../projects/prova.json')
        //     .then(res => {
        //         var wanted = res.data.filter((obj) => {
        //             return obj.id === parseInt(id);
        //         }).pop()
        //         this.setState({
        //             project: wanted
        //         })
        //     })

    }

    render(){
 
        const project = this.state.project ? (
            <div className="percorso">
                <Helmet>
                    <title>{ this.state.project.titolo }</title>
                    <meta name="description" content={this.state.project.testo.slice(0,100)} />
                </Helmet>
                <NavigationBar isSmall={true} />
                <h1 className="percorso-title mb-4 text-uppercase col-12 text-center text-xl-left col-xl-2">{ this.state.project.titolo }</h1>
                <div className="percorso-body pb-3 position-relative col-md-10 col-xl-8 mx-auto px-0">
                    <div className="parallax-img position-absolute" style={{ 'backgroundImage': 'url('+process.env.REACT_APP_BACKEND_URL + this.state.project.sfondo.url+')', 'height': '300px', 'marginTop': '160px'}}>
                    </div>
                    <Carousel className="mb-4">
                        {this.state.project.immagini.map((image)=>
                            <Carousel.Item>
                                <img
                                className="d-block mx-auto"
                                src={process.env.REACT_APP_BACKEND_URL + image.url}
                                alt="First slide"
                                style={{height:75+'vh'}}
                                />
                            </Carousel.Item>
                        )}
                    </Carousel>
                    <ReactMarkdown className="pr-4 pt-5" source={this.state.project.testo} />
                </div>
            </div>
        ) : (
            <div className="text-center page">
                Loading percorso...
            </div>
        )

        return(
            <div className="page pt-120">
                <Container fluid="true">
                    <Row className="">
                        <Col md={12} xl={12} className="mt-5 mx-auto">
                            <div class="pb-5 mb-5">
                                { project }
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
        
    }
}

export default Project