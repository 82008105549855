import React, { Component } from 'react';
import {FormControl, FormLabel, FormGroup, FormText, FormCheck, Button}  from 'react-bootstrap';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import ReCAPTCHA from "react-google-recaptcha";
// chiave recaptcha segreta: 6LftzeIUAAAAAB5L8HfqkYXfYy7cXX9G2-mEIDaO

// chiave sendgrid: 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 

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Campo obbligatorio').min(2, 'Troppo corto!').max(70, 'Troppo lungo!'),
    email: Yup.string().email('E-mail non valida').required('Campo obbligatorio'),
    condizioni: Yup.bool().required('È necessario accettare il trattamento dei dati personali').oneOf([true], "È necessario accettare il trattamento dei dati personali"),
    message: Yup.string().required('Campo obbligatorio'),
  });
 
class ContactForm extends Component {

    render(){

        const recaptchaRef = React.createRef();

        return(
            <div>
                <Formik 
                    initialValues={{ name: '', email: '', condizioni: false, message: ''}}
                    initialErrors={{ name: true, email: true, condizioni: true, message: true }}
                    validationSchema={validationSchema}
                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(true);

                        if (!!recaptchaRef.current.getValue()) {
                            //console.log('Token prima di fare il post:')
                            //console.log(recaptchaRef.current.getValue());
                            // make async call
                            axios.post(process.env.REACT_APP_BACKEND_URL + '/form-contattis', {
                                nome: values.name,
                                email: values.email,
                                messaggio: values.message,
                                captchaToken: recaptchaRef.current.getValue(),
                            })
                            .then(response => {
                                // Handle success.
                                if(response.data.error === 1){
                                    alert(response.data.msg);
                                    recaptchaRef.current.reset();
                                }else{
                                    sendEmail();
                                    console.log('Well done!');
                                }
                            })
                            .catch(error => {
                                // Handle error.
                                console.log('An error occurred:', error);
                            });
                            //console.log('submit: ', values);

                            // send an email by using the email plugin
                            const sendEmail = function(){
                                axios.post(process.env.REACT_APP_BACKEND_URL + '/email/', {
                                    to: `${process.env.REACT_APP_EMAIL_CONTATTI}`,
                                    from: `${values.email}`,
                                    subject: 'Nuovo messaggio dal sito',
                                    isMultiple: true,
                                    text: `
                                        Nome: ${values.name}
                                        Email: ${values.email}
                                        Messaggio: ${values.message}
                                    `,
                                    html: `
                                        <h2>Nuovo messaggio dal sito</h2>
                                        <strong>Nome:</strong> ${values.name}<br />
                                        <strong>Email:</strong> ${values.email}<br />
                                        <strong>Messaggio:</strong> ${values.message}
                                    `
                                })
                                .then(response => {
                                    // Handle success.
                                    console.log('Well done!');
                                    window.location = '/thank-you/';
                                })
                                .catch(error => {
                                    // Handle error.
                                    console.log('An error occurred:', error);
                                });
                            }

                        } else {
                            // console.log('recaptcha non trovato, faccio execute')
                            recaptchaRef.current.execute();
                        }

                        setSubmitting(false);
                    }}>
                    {({values, isSubmitting, handleChange, handleBlur, handleSubmit, isValid, errors }) => (
                        <Form onSubmit={handleSubmit}>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey="6LftzeIUAAAAAA_Hvz0a06aUZYXtCpJJLCdZ4rOj"
                                onChange={response => {
                                    if (response) {
                                        // console.log('faccio submit');
                                        handleSubmit();
                                    } else {
                                        // console.log('execute recaptcha');
                                        recaptchaRef.current.execute();
                                    }
                                }}
                            />
                            <FormGroup>
                                <FormLabel>Nome e cognome</FormLabel>
                                <Field name="name" placeholder="" type="input" as={FormControl} isValid={!errors.name} onChange={handleChange} value={values.name} />
                                <FormText className="form-error"><ErrorMessage name="name" /></FormText>
                            </FormGroup>
                            <FormGroup>
                               <FormLabel>E-mail</FormLabel>
                                <Field name="email" placeholder="" type="email" as={FormControl} isValid={!errors.email} onChange={handleChange} value={values.email}/>
                                <FormText className="form-error"><ErrorMessage name="email" /></FormText>
                            </FormGroup>
                            <FormGroup>
                               <FormLabel>Messaggio</FormLabel>
                               <FormControl name="message" as="textarea" rows="3" isValid={!errors.message} onChange={handleChange} value={values.message}/>
                            </FormGroup>
                            <FormGroup>
                                <Field id="condizioni" name="condizioni" type="checkbox" as={FormCheck} label="Accetto il trattamento dei dati personali" custom isValid={!errors.condizioni} />
                                <FormText className="form-error"><ErrorMessage name="condizioni" /></FormText>
                                <FormText className="help-text">Ai sensi dell’articolo 13 del GDPR 679/2016 si informa che i dati forniti tramite la compilazione di questo form saranno trattati esclusivamente per dare riscontro alle vostre richieste e trattati in modo lecito, corretto e trasparente.</FormText>
                            </FormGroup>
                            <div>
                                <Button type="submit" disabled={isSubmitting}>Invia messaggio</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }

}

export default ContactForm

