import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';  


class MapContainer extends Component{
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onInfoWindowClose = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };

  render() {
    
    const style = {
      width: '100%',
      height: '100%'
    }

    return (
      <Map google={this.props.google}
        style={style}
        initialCenter={{
          lat: 45.0676067,
          lng: 7.665869
        }}
        zoom={14} 
        scrollwheel={false}
        styles = {[{
          stylers:  [
            { hue: "#b1a9c3" },
            { saturation: -80 },
            { lightness: 20 },
            { gamma: 1.51 }
          ]
        }]}
      >
        <Marker onClick={this.onMarkerClick} 
          name={"Annagrazia D'Antico"} 
          position={{ lat: 45.0676067, lng: 7.665869 }}
        />

        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow} 
          onClose={this.onInfoWindowClose}
        >
          <div>
            <h3>{this.state.selectedPlace.name}</h3>
            <p>Indirizzo dello studio di Annagrazia</p>
          </div>
        </InfoWindow>
      </Map>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCBTtvBIlcEA7TmV2FUGt6N0uU6Mg9wCJw'
})(MapContainer);
