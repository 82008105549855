import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';
import Home from './Home';
import About from './About';
import Blog from './Blog';
import Collaboratori from './Collaboratori';
import Orari from './Orari';
import Projects from './Projects';
import Post from './components/Post';
import Project from './components/Project';
import Contact from './Contact';
import Privacy from './Privacy';
import ThankYou from './ThankYou';
import { NoMatch } from './NoMatch';
import { Layout } from './components/Layout';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import "./App.scss";
import ScrollToTop from './components/ScrollToTop';
import withTracker from './components/withTracker';

import CookieConsent, { Cookies } from "react-cookie-consent";
import ReactGA from 'react-ga4';



function App() {

  function CookieBar() {
    const acceptedCookies = Cookies.get("gdprCookie");
  
    function callCookies() {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
    }
  
    if (acceptedCookies === "true") {
      callCookies();
      return null;
    }
  
    return (
      <CookieConsent
        onAccept={() => callCookies()}
        debug={true}
        acceptOnScroll={true}
        onDecline={() => {
          console.log("Cookie bloccato!");
        }}
        buttonText="Accetto i cookie"
        cookieName="gdprCookie"
        style={{ background: "#383937" }}
        buttonStyle={{
          fontWeight: "700",
          background: "#570A00",
          color: "white",
          textTransform: "uppercase",
        }}
        expires={150}
      >
        {"Questo sito web utilizza i cookie. Usiamo i cookie per personalizzare la tua esperienza utente, e per analizzare il nostro traffico. "}
        <a href="/privacy" style={{ color: "white", textDecoration: "underline"}}>
          {"Puoi modificare le tue impostazioni nella sezione Privacy Policy"}
        </a>{" "}
      </CookieConsent>
    );
  }

  function routeChange(){
    console.log('route changed');
  }

  return ( 
    <React.Fragment>
      <Helmet>
        <title>Annagrazia D'Antico</title>
        <meta name="description" content="Coreografa e danza" />
      </Helmet>
        <Router>
          <ScrollToTop />
          {/* <Jumbotron /> */}
          <Layout>
            <Route render={({location}) => (
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  timeout={450}
                  classNames="fade"
                >
                  <Switch location={location} onChange={() => routeChange()}>
                    <Route exact path="/" component={withTracker(Home) } />
                    <Route exact path="/bio" component={withTracker(props => <About {...props} />)} />
                    <Route exact path="/percorsi" component={withTracker(Projects)} />
                    <Route exact path="/percorsi/:project_id" component={withTracker(Project)} />
                    <Route exact path="/orari" component={withTracker(Orari)} />
                    <Route exact path="/blog" component={withTracker(Blog)} />
                    <Route exact path="/collaboratori" component={withTracker(Collaboratori)} />
                    <Route exact path="/contatti" component={withTracker(Contact)} />
                    <Route exact path="/blog/:post_id" component={withTracker(Post)} />
                    <Route exact path="/privacy" component={withTracker(Privacy)} />
                    <Route exact path="/thank-you" component={withTracker(ThankYou)} />
                    <Route component={withTracker(NoMatch)} />
                  </Switch>

                </CSSTransition>
              </TransitionGroup>
            )} />
          </Layout>
        </Router>
        {/* <CookieConsent
          onAccept={() => callCookies()}
          debug={true}
          acceptOnScroll={true}
          onDecline={() => {console.log("Cookie bloccato!")}}
          buttonText="Accetto i cookie"
          cookieName="gdprCookie"
          style={{ background: "#262626" }}
          buttonStyle={{ color: "#262626", fontWeight: "700", background: "#e7ceeb", textTransform: "uppercase" }}
          expires={150}
        >
         Questo sito utilizza i cookie.{" "}
          <a href="/privacy" style={{ color: "white", textDecoration: 'underline' }}>
          Scopri di più su come utilizziamo i cookie e su come modificare le tue impostazioni.
          </a>{" "}
          Se continui la navigazione acconsenti all'utilizzo dei cookie da parte nostra.
        </CookieConsent> */}
        <CookieBar />
    </React.Fragment>
  );
}

export default App;
