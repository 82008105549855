import React from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Footer from './components/Footer';
import NavigationBar from './components/NavigationBar';

// contenuti
import imgAbout1 from './assets/img/bio/cerchi-brush.jpg';
import imgAbout2 from './assets/img/bio/cerchi-brush2.jpg';
import AboutCV from './assets/dl/Curriculum_sintetico_Annagrazia.pdf';


const About = () => {

    return(
        <div className="page pt-120">
            <Helmet>
                <title>Biografia | Annagrazia D'Antico</title>
                <meta name="description" content="Danzatrice, coreografa e insegnante, Annagrazia D'Antico è specializzata in danza moderna e contemporanea, tecnica Graham e personal trainer." />
            </Helmet>
            <NavigationBar isSmall={true} />
            <Container fluid="true" className="position-relative">
                <Row className="align-items-center">
                    <Col md={6} className="px-3 px-lg-5">
                        <h1 className="title" style={{'marginLeft': '-5px'}}>Bio</h1>
                        <h2 className="subtitle mb-4"> Annagrazia D'Antico</h2>
                        <p>Sono nata a Torino ma sento fortemente le mie origini pugliesi.<br></br>Ogni anno faccio ritorno al paese dei miei genitori nell’entroterra salentino. Ogni volta ne apprezzo l’atmosfera, i profumi, il sole, gli ulivi, la terra rossa e mi rigenero riavvicinandomi ai luoghi della mia anima.</p>
                        <p>Il mio lavoro è frutto di una scelta che ha guidato tutto il mio percorso professionale: migliorare me stessa attraverso lo studio e la pratica, per poter essere utile a tutti coloro che vogliono accedere al movimento in generale, semplicemente facendoli stare meglio e aiutandoli a raggiungere i propri obiettivi.</p>
                    </Col>
                    <Col md={6} className="">
                        <div className="position-relative  mt-5">
                            <img
                                className="d-block w-100"
                                src={imgAbout1}
                                alt="Annagrazia D'Antico mentre danza"
                                />
                        </div>
                    </Col>
                    <Col xs={12} md={8} className="mx-auto text-center">
                        <blockquote className="p-4 quote mt-4">“Voglio aiutare sia gli <i>Artisti</i> sia tutti coloro che vogliono accedere al movimento in generale, <i>semplicemente</i> facendoli stare meglio e aiutandoli a raggiungere i propri obiettivi.”</blockquote>
                    </Col>
                    <Col sm={6} className="mb-4">
                        <div className="position-relative">
                        <img
                                className="d-block w-100"
                                src={imgAbout2}
                                alt="Annagrazia D'Antico"
                                />
                            {/* <LazyLoadImage
                                className="d-block w-100"
                                alt="Image"
                                src={imgAbout2}
                                srcSet="https://placeimg.com/320/240/nature 3200w, https://placeimg.com/640/480/nature 640w, https://placeimg.com/1000/800/nature 1000w"
                                sizes="(min-width: 768px) 50vw, 100vw" /> */}
                        </div>
                    </Col>
                    <Col sm={6} className="mb-4 px-3 px-lg-5">
                    <p>Mi piace realizzare coreografie e spettacoli in collaborazione con artisti che si dedicano ad altri linguaggi artistici quali ad esempio la scultura, l’architettura, la pittura e il design.</p>
                    <p>Amo la danza da quando ero molto piccola, ho iniziato a studiare danza classica presso la Scuola di Sara Acquarone. In seguito ho scoperto la danza moderna e contemporanea, dapprima la Tecnica Graham e in seguito le Tecniche Humphrey, Horton e Contemporaneo.</p>
                    <p>Mi dedico con attenzione da diversi anni alla Postura, non solo dei danzatori, e questo mi ha portato a conseguire il diploma in Posturologia.</p>
                    </Col>
                    <Col xs={10} className="mb-5 mt-5 px-5 mx-auto text-center">
                        <a href={AboutCV} target="_blanck"><Button>Scarica il mio curriculum</Button></a>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}

export default About