import React from 'react';


// contenuti
import imgInsta from '../assets/img/icone/instagram.svg';
import imgFacebook from '../assets/img/icone/facebook.svg';



export const Social = (inHome) => (

    <div className={inHome ? ("mb-4 mt-2 d-flex align-items-center justify-content-start"):("mb-3 d-flex justify-content-start")} >
        {/* <div className="mr-3 contact-icon">
            <a href="" target="_blank"><img src={ imgFb } alt="" width="30" height="30" /></a>
        </div> */}
        <div className="mr-3 contact-icon">
            <a href="https://www.instagram.com/annagraziadantico/" target="_blank" rel="noopener noreferrer"><img src={ imgInsta } alt="" width="30" height="30" /></a>
            <a href="https://www.facebook.com/ADA.dancestudio" target="_blank" rel="noopener noreferrer" className="ml-3"><img src={ imgFacebook } alt="" width="30" height="30" /></a>
        </div>
        
    </div>

)

