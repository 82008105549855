import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from './components/Footer';
import ProjectList from './components/ProjectList';
import NavigationBar from './components/NavigationBar';


class Projects extends Component {

    render() {

        return(
            <div className="page pt-120">
                <Helmet>
                    <title>Dance Studio, Percorso Gravity® e Posturale | Annagrazia D'Antico</title>
                    <meta name="description" content="Il mondo di Annagrazia si divide in Dance Studio (lezioni di danza moderna e contemporanea), Percorso Gravity Training System e Percorso Posturale." />
                </Helmet>
                <NavigationBar isSmall={true} />
                <Container fluid="true" className="pb-5">
                    <Row className="mb-5">
                        <Col sm={12}>
                            <h1 className="title text-center">Percorsi</h1>
                        </Col>
                    </Row>
                    <Row>
                        <ProjectList/>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}

export default Projects