import React, { Component } from 'react';
import Helmet from 'react-helmet';
import PostList from './components/PostList';
import { Container, Row, Col } from 'react-bootstrap';
import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';


class Blog extends Component {

    render() {
        return(
            <div className="page pt-120">
                <Helmet>
                    <title>News & Eventi | Annagrazia D'Antico</title>
                    <meta name="description" content="Scopri news ed eventi di Annagrazia D'Antico e curiosità e novità dal mondo della danza moderna e contemporanea, dei principali metodi, del Gravity." />
                </Helmet>
                <NavigationBar isSmall={true} />
                <Container fluid="true">
                    <Row className="align-items-center">
                        <Col xl={12} className="mb-5">
                            <h1 className="title text-center">News</h1>
                            <h2 className="subtitle text-center">Le mie news e i miei eventi</h2>
                        </Col>
                        <Col md={10} xl={8} className="mx-auto">
                            <PostList nrOfPost="100" />
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}

export default Blog