import React from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';
import CookieConsent, { Cookies } from "react-cookie-consent";
import ReactGA from 'react-ga4';

class ThankYou extends React.Component {

    componentDidMount(){

        const acceptedCookies = Cookies.get("gdprCookie");

        if (acceptedCookies === "true") {
            ReactGA.event({
                category: "FormContattiInviato",
                action: "FormContatti",
                label: "Form Contatti Inviato", // optional
                value: 1, // optional, must be a number
            });
        }
    }

    render(){
        return(
            <div className="page pt-120">
                <Helmet>
                    <title>Thank you - Annagrazia D'Antico</title>
                    <meta name="description" content="Lorem ipsum" />
                </Helmet>
                <NavigationBar isSmall={true} />
                    <div className="page-height d-flex flex-column">
                        <Container fluid={true} className="d-flex flex-column flex-grow-1">
                            <Row className="align-items-center text-center">
                                <Col md={10} lg={9} xl={8} className="mx-auto">
                                    <h1 className="title text-center text-nowrap">Thank you</h1>
                                    <h2 className="subtitle mb-5">Grazie per avermi contattato</h2>
                                    <p>Ho ricevuto la tua mail e ti contatterò nel più breve tempo possibile.</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                <Footer />
            </div>
        )
    }
}

export default ThankYou
