import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import ContactForm from './components/Form';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from './components/Footer';
import { Social } from './components/Social';
import MapContainer from './components/MapContainer';
import NavigationBar from './components/NavigationBar';

// contenuti
import imgIndirizzo from './assets/img/icone/maps-and-flags.svg';
import imgMail from './assets/img/icone/paper-plane.svg';
import imgMobile from './assets/img/icone/smartphone.svg';


class Contact extends Component {

    render(){
        return(
            <div className="page pt-120">
                <Helmet>
                    <title>Contatti, Social e Numeri Utili | Annagrazia D'Antico</title>
                    <meta name="description" content="AD'A Dance Studio si trova in Corso Vittorio Emanuele II, 100, 10121 Torino. Chiama, scrivi una mail o compila il form per richiedere informazioni." />
                </Helmet>
                <NavigationBar isSmall={true} />
                <div className="page-height d-flex flex-column">
                    <Container fluid={true} className="d-flex flex-column flex-grow-1">
                        <Row className="align-items-center">
                            <Col md={5} className="mr-auto pl-5 py-5">
                                <div className="location-box">
                                    <h1 className="title">Contatti</h1>
                                    <h2 className="subtitle mb-4">Social e numeri utili</h2>
                                    <div className="mr-5">
                                        <div className="mb-3 d-flex align-items-center">
                                            <div className="mr-3 contact-icon">
                                                <img src={ imgIndirizzo } alt="Avocado" width="30" height="30" />
                                            </div>
                                            <div>
                                                <span className="contact-label d-block">Indirizzo</span>
                                                <address className="mb-0">Corso Vittorio Emanuele II, 100 - 10121 TORINO, Italia</address>
                                            </div>
                                        </div>
                                        <div className="mb-3 d-flex align-items-center">
                                            <div className="mr-3 contact-icon">
                                                <img src={ imgMobile } alt="tel" width="30" height="30" />
                                            </div>
                                            <div>
                                                <span className="contact-label d-block">Telefono</span>
                                                <a className="contact-link" href="tel:01119015408">011 190 154 08</a> <span className="mx-1">—</span>  <a className="contact-link" href="tel:3454425642">+39 345 442 5642</a>
                                            </div>
                                        </div>
                                        <div className="mb-3 d-flex align-items-center">
                                            <div className="mr-3 contact-icon">
                                                <img src={ imgMail } alt="mail" width="30" height="30" />
                                            </div>
                                            <div>
                                                <span className="contact-label d-block">E-mail</span>
                                                <a className="contact-link" href="mailto:info@ada-dancestudio.com">info@ada-dancestudio.com</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <h3 className="mt-5" >Social</h3>
                                        <Social inHome={false}/>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} className="ml-auto px-0 py-5 home-section-location mt-5">
                                <div className="virtual-tour-wrapper">
                                    <iframe className="virtual-tour" src="https://www.google.com/maps/embed?pb=!4v1620733453582!6m8!1m7!1sCAoSLEFGMVFpcFBadXR5THFnVzRxd3pDOGdZYnZyNTFzeThrWU50VmR0RDR3OVBr!2m2!1d45.067625160856!2d7.665941387213!3f357.39412935181423!4f-11.854552999263547!5f0.7820865974627469" allowFullScreen="" loading="lazy"></iframe>
                                </div>
                            </Col> 
                        </Row>
                        <Row className="align-items-center bg-grigio-chiaro">
                            <Col lg={6} className="p-5 mx-auto order-lg-2">
                                <h2 className="text-center mb-3">Contattami</h2>
                                <ContactForm />
                            </Col>
                            <Col lg={6} className="mx-auto px-0 order-lg-1">
                                <div className="map-wrapper">
                                    <MapContainer />
                                </div>
                            </Col>
                            
                        </Row>
                    </Container>
                    <Footer />
                </div>
            </div>
        )
    }

}

export default Contact