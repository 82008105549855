import React, { Component } from 'react';
import axios from 'axios';
import Helmet from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import Moment from 'react-moment';
import 'moment/locale/it';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import ReactMarkdown from 'react-markdown';

// contenuti
import imgLocation from '../assets/img/icone/maps-and-flags.svg';
import imgEvento from '../assets/img/icone/event.svg';
import imgNews from '../assets/img/icone/megaphone.svg';
import imgPensiero from '../assets/img/icone/comic.svg';
import ImgThumbnail from '../assets/img/thumbnail.jpg';



class Post extends Component{

    state = {
        post: null
    }

    componentDidMount(){
        
        let id = this.props.match.params.post_id;
       
        axios.get(process.env.REACT_APP_BACKEND_URL + '/articolos/' + id)
            .then(res => {
                this.setState({
                    post: res.data
                })
            })

    }

    render(){
        const post = this.state.post ? (
            <div className="">
                <Helmet>
                    <title>{ this.state.post.title }</title>
                    <meta name="description" content={this.state.post.testo.slice(0,100)} />
                </Helmet>
                <NavigationBar isSmall={true} />
                <Container fluid="true">
                    <Row>
                        <Col md={10} xl={8} className="mt-5 mx-auto mb-5">
                            <div className="cornice-3">

                            
                                {this.state.post.video_youtube != null ? (
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <iframe title="video" className="embed-responsive-item" src={this.state.post.video_youtube } allowFullScreen></iframe>
                                    </div>
                                ) : (
                                    <img
                                        className="d-block w-100 img-fluid "
                                        src={this.state.post.cover ? process.env.REACT_APP_BACKEND_URL + this.state.post.cover.url : ImgThumbnail}
                                        alt={ this.state.post.titolo }
                                    />
                                )}

                            </div>
                            <div className="post-body pt-5 pb-4">
                                <div>
                                    {this.state.post.tipologia === 'evento' ? (
                                        <img src={imgEvento} width="25" className="mr-2" alt="icona evento" />
                                    ):(
                                        this.state.post.tipologia === 'news' ? (
                                            <img src={imgNews} width="25" className="mr-2" alt="icona news" />
                                        ): (
                                            <img src={imgPensiero} width="25" className="mr-2" alt="icona pensiero" />
                                        )
                                    )}
                                    <span className="post-date">
                                        <Moment locale="it" format="DD MMMM YYYY">{this.state.post.data}</Moment>
                                    </span>
                                </div>
                                
                                <h1 className="mb-4 mt-3">{ this.state.post.titolo }</h1>
                                <ReactMarkdown source={ this.state.post.testo } />
                                <div className="post-dove mt-4">
                                    <p><img src={imgLocation} width="25" className="mr-2" alt="icona location" />{ this.state.post.dove }</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} className="mt-5 mb-5">
                        <div className="row">
                        {this.state.post.immagini.map( (image, index) =>{
                                return (
                                    <Col sm={7} className={(index%2 ===0)?('ml-auto'):('mr-auto')}>
                                    {/* <Parallax className="custom-class" y={[0, 20]}>
                                         <div className="parallax-img position-relative no-repeat" style={{ 'backgroundImage': 'url('+process.env.REACT_APP_BACKEND_URL + image.url+')', 'height': '400px', 'marginTop': '60px','marginBottom': '80px'}}></div>
                                    </Parallax> */}
                                    <img src={process.env.REACT_APP_BACKEND_URL + image.url} alt={image.name} className="img-fluid my-3" />
                                    </Col>
                                )
                            })}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        ) : (
            <div className="text-center page">
                Loading post...
            </div>
        )

        return(
            <div className="page pt-120">
                { post }
                <Footer />
            </div>
        )
        
    }
}



export default Post