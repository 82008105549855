import React from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col, Table } from 'react-bootstrap';
import Footer from './components/Footer';
import NavigationBar from './components/NavigationBar';

import imgOrari from './assets/img/locandina-ADA_particolare_ok.jpg';
import imgQRcode from './assets/img/ORARI_ADA_qrcode.jpg';

const About = () => {

    return(
        <div className="page page-height d-flex flex-column pt-120">
            <Helmet>
                <title>Orari e calendari corsi | Annagrazia D'Antico</title>
                <meta name="description" content="Scopri gli orari di AD'A Dance Studio: lezioni di danza e di Gravity Training System su prenotazione e di percorso posturale." />
            </Helmet>
            <NavigationBar isSmall={true} />
            <Container fluid="true" className="position-relative">
                <Row className="align-items-start mb-5">
                    <Col sm={12} className="px-3 px-lg-5">
                        <h1 className="title" style={{'marginLeft': '-5px'}}>Orari corsi</h1>
                        <h2 className="subtitle mb-4">AD'A Dance studio</h2>
                    </Col>
                    <Col lg={6} className="px-3 px-lg-5 text-center">

                        <img
                            className="d-block w-100 my-5 px-md-5 px-lg-0 px-xl-5"
                            src={imgOrari}
                            alt="Volantino lezioni"
                            />
                        <a href="https://sportclubby.app.link/ovzwy9hEcib" target="_blank" rel="noopener noreferrer" className="btn btn-lg btn-primary d-md-none mb-4 d-inline-block">Prenota una lezione</a>
                    </Col>
                    <Col lg={6} className="mx-auto text-center px-3 px-lg-5">
                        <p class="text-uppercase" style={{'color':'#501200','font-size': '1.5rem'}}>Orario lezioni</p>
                        <p>Le lezioni di danza classica e moderna si potranno seguire anche online. </p>
                        <p>Potete consultare i nostri <strong>orari direttamente sull'app di Sportclubby</strong>, cliccando sul bottone seguente oppure <strong>scannerizzando il QR code</strong> sottostante.</p>
                        
                        <Row className="mb-3">
                            <Col md={6} className="d-flex align-items-center justify-content-md-end justify-content-center">
                                <a href="https://indd.adobe.com/view/deeb3571-5c55-42d5-bf46-b01551465cf9" target="_blank" rel="noopener noreferrer" className="btn btn-primary">Consulta gli orari</a>
                            </Col>
                            <Col md={6} className="d-flex align-items-center justify-content-center justify-content-md-start">
                                <img
                                    className="d-block"
                                    src={imgQRcode}
                                    alt="QR code orario lezioni"
                                    style={{'max-width':'200px'}}
                                />
                            </Col>
                        </Row>

                        <p>Per qualsiasi dubbio, la segreteria è disponibile dalle 09,00 alle 20,00 dal lunedì al venerdì per fornire tutte le informazioni desiderate su orari e prezzi. </p>
                        <p>Vi aspettiamo!</p>
                        <p><strong>Annagrazia e Lo STAFF di AD'A dance studio</strong></p>
                        <a href="https://sportclubby.app.link/ovzwy9hEcib" target="_blank" rel="noopener noreferrer" className="btn btn-lg btn-primary mt-3">Prenota una lezione</a>
                    </Col>
                </Row>
            </Container>
            <div class="mt-auto">
                 <Footer />
            </div>
        </div>
    )
}

export default About